<template>
  <CCard>
    <CCardHeader>
      Device List
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol sm="12">
          <CDataTable
            hover
            small
            fixed
            table-filter
            sorter
            items-per-page-select
            :items-per-page="15"
            :fields="cmdbDeviceListFields"
            :items="devices"
            :noItemsView="{ noItems: 'No Item~' }"
            pagination
          >
            <template #last_heartbeat="{item}">
              <td class="py-2" v-if="item.last_heartbeat > 0">
                <CCardText color="green">{{ item.last_heartbeat | dateFormat}}</CCardText>
              </td>
              <td class="py-2" v-if="item.last_heartbeat === 0">
                <p style="color:red">No Heartbeat</p>
              </td>
            </template>
            <template #action="{item}">
              <td class="py-2">
                <CButtonGroup>
                  <CButton
                    color="info"
                    square
                    @click="requestMsg=item.uuid;requestMsgModal=true;"
                  >
                    Show UUID
                  </CButton>
                  <CButton
                    color="primary"
                    square
                    @click="$router.push('/cmdb/device/view/'+item.id)"
                  >
                    View
                  </CButton>
                  <CButton
                    color="danger"
                    square
                    @click="deletePortForward(item.id)"
                  >
                    Delete
                  </CButton>

                </CButtonGroup>
              </td>
            </template>
          </CDataTable>
        </CCol>
      </CRow>
    </CCardBody>
    <CToaster :autohide="3000">
      <template v-for="toast in fixedToasts">
        <CToast
          :key="'toast' + toast"
          :show="true"
          :color="alertLevel"
          header="Alert"
        >
          {{ returnMsg }}
        </CToast>
      </template>
    </CToaster>
    <CModal
      title="Message"
      color="primary"
      :show.sync="requestMsgModal"
    >
      {{ requestMsg }}
      <template #footer>
        <CButton @click="requestMsgModal=false" color="primary">Close</CButton>
      </template>
    </CModal>
  </CCard>
</template>

<script>
const cmdbDeviceListFields = [
  { key: "id", label: "ID" },
  { key: "name", label: "Device Name" },
  { key: "type", label: "Type" },
  { key: "hostname", label: "Hostname" },
  { key: "main_ip", label: "Main IP" },
  { key: "last_heartbeat", label: "Last Heartbeat" },
  {
    key: "action",
    label: "Action",
    sorter: false,
    filter: false,
    _style: "width: 20%"
  }
];

export default {
  name: "DeviceList",
  data() {
    return {
      fixedToasts: 0,
      returnMsg: "",
      alertLevel: "success",
      requestMsgModal: false,
      requestMsg: null,
      cmdbDeviceListFields,
      devices: [],
    };
  },
  mounted: function() {
    this.onLoadPage();
  },
  methods: {
    onLoadPage: function() {
      this.$axios.get("user/cmdb/device/list", {})
        .then((response) => {
          if (response.data.code === 200) {
            this.devices = response.data.devices.map(item => {
              return {
                ...item,
                type: item.type.type_name
              }
            });
          }
        });
    },
  }
};
</script>